@use './mixins.scss';
@use './colors.module.scss';

.aboutMeIcon {
    width: 3rem;
    height: auto;
}

@mixin cardTitle {
    text-transform: uppercase;
    font-size: medium;
    letter-spacing: 0.3rem;

    @include mixins.medium-screen {
        font-size: x-large;
        font-weight: 600;
        letter-spacing: 0.5rem;
    }
}

.projectDetailsTitle {
    font-size: medium;
    margin-bottom: 0.3rem;
}

.detailedEntityIconListTitle {
    @extend .projectDetailsTitle;
    letter-spacing: 0.04rem;
}

.detailedEntityIconListIcon {
    color: colors.$color-task-icon-fill;
    width: 3rem;
    height: auto;
}

@mixin tabstrip-underline($startingColor: colors.$color-tabstrip-underline) {
    content: "";
    position: absolute;
    background-image: linear-gradient(to right, $startingColor 50%, $startingColor);
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 0.2rem;
}