@use '../../styles/mixins.scss';

.socialMedia {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;

    @include mixins.medium-screen {
        column-gap: 1rem;
    }

    @include mixins.large-screen {
        flex-direction: column;
        row-gap: 1rem;
    }
}

//needed since <a> adds a padding in the bottom
.linkImage {
    display: block;
}

.linkedInIcon {
    @extend .linkImage;
    width: 2rem;
}