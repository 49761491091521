@font-face {
    font-family: "Antonio";
    src: url('./../fonts/antonio/Antonio-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: "Antonio";
    src: url('./../fonts/antonio/Antonio-ExtraLight.ttf');
    font-weight: 200;
}

@font-face {
    font-family: "Antonio";
    src: url('./../fonts/antonio/Antonio-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: "Antonio";
    src: url('./../fonts/antonio/Antonio-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: "Epilogue";
    src: url('./../fonts/epilogue/Epilogue-Regular.ttf');
    font-weight: 500;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-ExtraLight.ttf');
    font-weight: 200;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-ExtraBold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-Black.ttf');
    font-weight: 900;
}

@font-face {
    font-family: "SourceSans3";
    src: url('./../fonts/sourceSans3/SourceSans3-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}