@use '../../styles/mixins.scss';
@use '../../styles/components.scss' as comp;
@use '../../styles/dimensions.module.scss' as dimens;

$gridSpacing: 2rem;

.skills {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    @include mixins.large-screen {
        display: grid;
        grid-auto-rows: 1fr;
        align-items: stretch;
        padding: 0 dimens.$padding-page;
        column-gap: $gridSpacing;
        grid-template-columns: repeat(3, 1fr);
    }

    &:hover {
        grid-auto-rows: auto;
    }
}

.title {
    margin: 0;
    @include comp.cardTitle;
    font-size: x-large;
}

.skillContentList {
    overflow: hidden;
    height: 0;
    opacity: 0;
}

.description {
    margin: 0;
    margin-top: 1rem;
    font-size: large;
}