$color-primary-dark: #0e4463;
$color-primary: #1e6085;
$color-primary-light: #3c88b4;
$color-primary-lighter: #b4d9ee;
$color-secondary: #fdfdf7;
$color-secondary-600: #b2b49d;
$color-secondary-500: #c6c6b3;
$color-secondary-400: #d2d2b6;
$color-secondary-300: #e1e1cb;
$color-secondary-200: #f1f1df;
$color-accent: #73216B;
// $color-accent-light: #ca8cc4;
// $color-accent-light: #cea5ca;
// $color-accent-other: #048004;
$color-full-transparent: rgba(0, 0, 0, 0);

/* components */
$color-accordion-item-box-shadow: transparentize($color-primary, 0.5);
$color-accordion-item-chevron-fill: $color-primary;
$color-accordion-item-header-background: transparentize($color-secondary-200, 0.4);
$color-accordion-item-content-background: $color-secondary;

$color-backdrop: transparentize($color-secondary, 0.1);

$color-button-accent: $color-accent;
$color-button-text: $color-primary;
$color-button-text-hover: $color-primary-light;

$color-font: $color-primary;
$color-font-light: $color-secondary;

$color-glassy-background: $color-secondary-300;

$color-link: $color-accent;

$color-loading-spinner: $color-primary;

/* features */
$color-about-me-text: $color-primary;

$color-background-shape-from: transparentize($color-primary, 0.5);
$color-background-shape-to: transparentize($color-primary-lighter, 0.5);

$color-banner-background: $color-primary;
$color-banner-box-shadow: $color-primary-light;
$color-banner-headline: $color-primary-lighter;
$color-banner-headline-accent: $color-font-light;
$color-banner-text-shadow: $color-primary-light;
$color-banner-quote: $color-font-light;
$color-banner-products-button-font: $color-secondary-200;
$color-banner-products-button-background: lighten($color-primary, 5);

$color-burger-menu-background: $color-secondary;
$color-burger-menu-icon-fill: $color-secondary;
$color-burger-menu-item-caption: $color-accent;

$color-buy-me-a-coffee-background: $color-primary-light;
$color-buy-me-a-coffee-font: $color-font-light;
$color-buy-me-a-coffee-icon: $color-secondary-600;

$color-carousel-chevron: $color-primary;
$color-carousel-chevron-hover: $color-primary-light;
$color-carousel-nav-button-background: $color-secondary;
$color-carousel-nav-button-outline: $color-primary-lighter;
$color-carousel-nav-button-hover: $color-primary;
$color-carousel-nav-button-inactive: $color-secondary-300;

$color-detailed-entity-icon-list-highlighted-entry: $color-accent;

$color-error-page-button-background: $color-accent;
$color-error-page-button-font: $color-font-light;
$color-error-page-icon: $color-primary;

$color-footer-background: $color-primary;
$color-footer-font: $color-font-light;

$color-header-background: $color-primary;

$color-logo: $color-secondary;

$color-task-icon-fill: $color-primary;

$color-tabstrip-underline: $color-font-light;
$color-tabstrip-underline-dark-mode: $color-primary;
$color-tabstrip-font-dark-mode: $color-primary;

$color-technologies-card-background: $color-primary-light;

$color-page-content-background: $color-secondary;
$color-page-highlighted-text: $color-accent;