@use '../../../styles/components.scss';

.icon {
    @extend .aboutMeIcon;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.description {
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.years {
    font-weight: 700;
}

.skills {
    font-weight: 500;
    margin-left: 1rem;
}

.and {
    margin-left: 2rem;
}

.skills {
    display: flex;
    flex-direction: column;
}