@use '../styles/colors.module.scss';
@use '../styles/mixins.scss';
@use '../styles/dimensions.module.scss' as dimens;

.backgroundWrapper {
    position: relative;
}

.content {
    background-color: colors.$color-page-content-background;
}

.pageSections {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageSection {
    z-index: 1;
    padding-top: 0.5rem;

    @include mixins.medium-screen {
        max-width: dimens.$page-width;
    }
}

.highlightedText {
    color: colors.$color-page-highlighted-text;
}
