@use '../../../styles/colors.module.scss';
@use '../../../styles/mixins.scss';
@use '../../../styles/components.scss';

$card-height: 13.5rem;
$transition-time: 500ms;

.skillCard {
    position: relative;
    cursor: pointer;
    pointer-events: none;

    @include mixins.large-screen {
        pointer-events: all;

        &:hover .topCard {
            transform: translateY(0);
        }

        &:hover .bottomCard {
            transform: translateY(0);
            opacity: 1;
            border-bottom: 3px solid colors.$color-font;
        }
    }

}

.topCard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mixins.glassy();

    @include mixins.large-screen {
        height: $card-height;
        transition: $transition-time;
        transform: translateY($card-height/2);
        z-index: 1;
    }
}

.title {
    padding: 1rem;
    margin: 0;
    align-self: center;
    @include components.cardTitle;
}

.bottomCard {
    opacity: 1;
    @include mixins.glassy;
    // border-bottom: 3px solid colors.$color-font;

    @include mixins.large-screen {
        height: $card-height;
        transform: translateY($card-height/2*-1);
        transition: $transition-time;
        opacity: 0;
    }
}