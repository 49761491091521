@use '../../styles/colors.module.scss';
@use '../../styles/components.scss';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    column-gap: 0.3rem;
}

.title {
    @extend .detailedEntityIconListTitle;
    margin-top: 0;
    margin: 0;
}

.highlightedEntry {
    font-weight: 600;
    color: colors.$color-detailed-entity-icon-list-highlighted-entry;
}