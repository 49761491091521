@use '../../../styles/mixins.scss';
@use '../../../styles/colors.module.scss';

.project {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    @include mixins.glassy;

    @include mixins.large-screen {
        padding: 2rem;
    }
}

.header {
    display: flex;
    flex-direction: column-reverse;
    font-family: "Antonio";
    row-gap: 1rem;
    align-items: flex-start;

    @include mixins.medium-screen {
        flex-direction: row;
        justify-content: space-between;
    }
}

.titleAndLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
}

.title {
    margin: 0;
    font-size: large;

    @include mixins.medium-screen {
        font-size: x-large;
    }

    @include mixins.large-screen {
        font-size: xx-large;
        margin-top: 1rem;
    }
}

.openInNewIcon {
    fill: colors.$color-primary;
}

.date {
    font-size: medium;
    font-weight: 600;
    align-self: flex-end;


    @include mixins.medium-screen {
        font-size: medium;
        align-self: auto;
    }
}

.goal {
    font-size: medium;
    font-weight: 600;

    @include mixins.medium-screen {
        font-size: large;
    }
}

.images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
}

.image {
    width: 100%;
    height: auto;
}

.projectDetails {
    margin-top: 2rem;
}