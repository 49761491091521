@use '../../styles/mixins.scss';
@use '../../styles/dimensions.module.scss' as dimens;

.aboutMe {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    
    @include mixins.large-screen {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        padding: 0.5rem dimens.$padding-page dimens.$padding-page dimens.$padding-page;
        min-width: dimens.$page-width;
    }
}