@use '../../../styles/mixins.scss';
@use '../../../styles/colors.module.scss';

.testimonial {
    width: fit-content;
    padding: 2.5rem 2rem 2rem 2rem;
    text-decoration: none;
    @include mixins.glassy();

    @include mixins.large-screen {
        padding: 2.3rem 2rem 1.8rem 2rem;
    }
}

.link {
    cursor: pointer;
}

.testimonialText {
    height: 100%;

    @include mixins.large-screen {
        width: 17rem;
    }
}

.quoteFrom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.author {
    margin-top: 0.8rem;
    margin-bottom: 0;
}

.job {
    margin: 0;
}