@use '../../../styles/dimensions.module.scss' as dimens;
@use '../../../styles/mixins.scss';

.testimonialList {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-bottom: dimens.$padding-page;
    flex-wrap: wrap;

    @include mixins.large-screen {
        flex-direction: row;
        width: dimens.$page-width;
        padding: 0.5rem dimens.$padding-page dimens.$padding-page dimens.$padding-page;
        gap: 1.5rem;
    }
}