@use '../../styles/colors.module.scss';
@use '../../styles/mixins.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.$color-backdrop;
    z-index: -1;
}

.closeButton {
    cursor: pointer;
    align-self: end;
    margin-top: 0.4rem;
    margin-right: 1.5rem;
}

.closeIcon {
    width: 2.5rem;
    height: auto;
}