@use '../../styles/colors.module.scss';

.loadingSpinner {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid colors.$color-loading-spinner;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}