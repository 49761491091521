@use '../../../styles/dimensions.module.scss' as dimens;
@use '../../../styles/mixins.scss';

.projects {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include mixins.large-screen {
        padding: 0 dimens.$padding-page;
    }
}

.loadingSpinner {
    align-self: center;
}