@use './styles/typography.scss';
@use './styles/colors.module.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "SourceSans3";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "SourceSans3";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
label,
span {
  color: colors.$color-font;
}

ul {
  list-style-type: square;
}