@use '../../styles/dimensions.module.scss' as dimens;
@use '../../styles/mixins.scss';

.technologies {
    display: flex;
    flex-direction: column;

    @include mixins.large-screen {
        padding: 0 dimens.$padding-page;
    }
}

.techStackList {
    @include mixins.glassy;
    padding: 0.7rem dimens.$padding-page dimens.$padding-page dimens.$padding-page;
}

.techStack {
    @include mixins.medium-screen {
        row-gap: 2.5rem;
    }
}

.loadingSpinner {
    align-self: center;
}