@use '../../styles/colors.module.scss';
@use '../../styles/mixins.scss';

.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: colors.$color-header-background;
    padding: 0.5rem;
}

.logo {
    width: 3rem;
    height: auto;
    color: colors.$color-logo;
    cursor: pointer;

    @include mixins.medium-screen {
        width: 5rem;
    }
}

.navigation {
    grid-column: 1/3;
    grid-row-start: 2;
    justify-self: center;
    align-self: center;
    color: colors.$color-font-light;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

.language {
    grid-column-start: 2;
    grid-row-start: 1;
    justify-self: end;
    color: colors.$color-font-light;
}