@use '../../../styles/mixins.scss';
@use '../../../styles/colors.module.scss';

.aboutMeItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mixins.glassy;
    padding: 0.5rem 1rem 1rem 1rem;
    color: colors.$color-about-me-text;
    row-gap: 0.5rem;
    align-items: start;
}

.iconAndTitle {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-self: end;
}

.title {
    text-transform: uppercase;
    letter-spacing: 0.2rem;
}

.content {
    font-weight: 600;
}