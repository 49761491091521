@use '../../styles/colors.module.scss';

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: colors.$color-footer-background;
    color: colors.$color-footer-font;
    padding: 0.5rem 0;
    z-index: 1;
}