@use '../../../styles/colors.module.scss';
@use '../../../styles/mixins.scss';

.skillContent {
    display: flex;
    flex-direction: column;
    color: colors.$color-font;
    position: relative;
    align-items: flex-start;

    @include mixins.large-screen() {
        padding: 0 1rem;
    }
}

.description {
    position: relative;
    padding: 1rem;
    width: 100%;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 10%;
        border-bottom: 2px solid colors.$color-font;
    }

    &:last-child::after {
        border: none;
    }
}