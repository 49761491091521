@use '../../../../styles/mixins.scss';

.myTasks {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 1rem;
    row-gap: 2rem;

    @include mixins.medium-screen {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 0;
        column-gap: 1rem;
    }
}