@use '../../../../../styles/colors.module.scss';
@use '../../../../../styles/components.scss';

.task {
    display: flex;
    flex-direction: column;
    color: colors.$color-font;
}

.icon {
    @extend .detailedEntityIconListIcon
}