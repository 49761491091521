@use '../../../styles/components.scss';

.icon {
    @extend .aboutMeIcon;
}

.content {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 0.5rem;
}