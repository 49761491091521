@use '../../../styles/mixins.scss';
@use '../../../styles/colors.module.scss';

.carouselItem {
    position: relative;
    scroll-snap-align: center;
    box-sizing: border-box;
    flex-shrink: 0;
}

.zoomable {
    @include mixins.medium-screen() {
        cursor: pointer;
    }
}