@use '../../styles/colors.module.scss';

.quoteAndAuthor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.quote {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
}

.quotationMark {
    font-family: "Antonio";
    font-style: italic;
}

.quotationMarkLeft {
    @extend .quotationMark;
    align-self: start;
    transform: translateY(-1.8rem);
}

.quotationMarkRight {
    @extend .quotationMark;
    align-self: end;
    transform: translateY(2rem) translateX(-0.5rem);
}

.quoteText {
    margin: 0;
    font-style: italic;
}

.author {
    align-self: end;
}