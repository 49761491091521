@use '../../../styles/colors.module.scss';
@use '../../../styles/mixins.scss';


.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: colors.$color-accordion-item-header-background;

    @include mixins.large-screen {
        &:hover {
            box-shadow: 0 1px 4px colors.$color-accordion-item-box-shadow;
            font-weight: 600;
        }
    }
}

.headerIsOpen {
    box-shadow: 0 1px 4px colors.$color-accordion-item-box-shadow;
    font-weight: 600;
}

.title {
    font-size: medium;
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
}

.chevron {
    fill: colors.$color-accordion-item-chevron-fill;
    pointer-events: none;
    margin-right: 0.5rem;
}

.chevronUp {
    @extend .chevron;
    transform: rotate(90deg);
    transition: transform 0.3s;
    flex-shrink: 0;
}

.chevronDown {
    @extend .chevron;
    transform: rotate(-90deg);
    transition: transform 0.3s;
    flex-shrink: 0;
}

.content {
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    @include mixins.glassy(colors.$color-accordion-item-content-background);
}

.contentIsOpen {
    transition: height 0.5s ease-in-out;
}

.childWrapper {
    padding: 0.5rem;
    padding-bottom: 1rem;
}