@use '../../../../styles/colors.module.scss';
@use '../../../../styles/components.scss';
@use '../../../../styles/mixins.scss';

.customer {
    display: flex;
    flex-direction: row;
    color: colors.$color-font;
    column-gap: 1rem;
    align-items: center;
}

.logo {
    height: 2.5rem;
    margin-left: 1rem;

    @include mixins.medium-screen {
        height: 3.4rem;
    }
}

.name {
    @extend .projectDetailsTitle;
}

.noAdditionalInfo {
    margin: 0;
}