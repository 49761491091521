@use '../../../../styles/dimensions.module.scss' as dimens;

.projectDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabstrip {
    justify-content: space-between;
    width: dimens.$project-details-width;
}

.tabstripContent {
    align-self: center;
    width: dimens.$project-details-width;
    padding-top: 1rem;
}