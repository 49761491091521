@use '../../../styles/colors.module.scss';
@use '../../../styles/mixins.scss';

.carouselContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.carouselItemsContainer {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

.carouselItemsContainer::-webkit-scrollbar {
    display: none;
}

.chevron {
    width: 4rem;
    height: auto;
    fill: colors.$color-carousel-chevron;

    &:hover {
        fill: colors.$color-carousel-chevron-hover;
    }
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 0.5rem;
}

.navButton {
    width: 0.5rem;
    height: 0.5rem;
    background-color: colors.$color-carousel-nav-button-background;
    outline: 1px solid colors.$color-primary-lighter;
    outline: 1px solid colors.$color-carousel-nav-button-outline;
    border: none;
    border-radius: 50%;
    padding: 0;

    &:hover {
        @include mixins.medium-screen {
            background-color: colors.$color-carousel-nav-button-hover;
            outline: none;
        }
    }
}

.navButtonActive {
    background-color: colors.$color-carousel-nav-button-hover;
    outline: none;
}

.navButtonInactive {
    fill: colors.$color-carousel-nav-button-inactive;
    cursor: default;

    &:hover {
        fill: colors.$color-carousel-nav-button-inactive;
    }
}